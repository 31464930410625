import React, { useEffect, useState } from "react"; 
import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
function Alumni() {
    const [imagePreview, setImagePreview] = useState("https://webapi.entab.info/api/image/NCSR/public/Images/user.png");
    const [formData, setFormData] = useState({
        attachments1: null,
        name: "",
        motherName: "",
        dob: "",
        experience: "",
        phone1: "",
        phone2: "",
        phone: "",
        email: "",
        address: "",
        address1: "",
        location: "",
        fromYear: "",
        toYear: "",
        howKnow: "",
        howKnow1: "",
        qualification: "",
        profession: "",
        school1: "", 
        message: "", 
        messageTitle: ""
      });
    
      const [errors, setErrors] = useState({});
      const [successMessage, setSuccessMessage] = useState("");
    
      const initialFormState = {
        attachments1: null,
        name: "",
        motherName: "",
        dob: "",
        experience: "",
        phone1: "",
        phone2: "",
        phone: "",
        email: "",
        address: "",
        address1: "",
        location: "",
        fromYear: "",
        toYear: "",
        howKnow: "",
        howKnow1: "",
        qualification: "",
        profession: "",
        school1: "", 
        message: "", 
        messageTitle: ""
    };
    
      const handleChange = (e) => {
        const { name, value, type, files } = e.target;
      
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Reset only this field's error
      
        if (type === "file" && name === "attachments1") {
          const file = files[0];
          if (file) {
            const validTypes = ["image/jpeg", "image/jpg", "application/pdf"];
            if (!validTypes.includes(file.type)) {
              setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Invalid file type. Only JPG, JPEG, and PDF are allowed.",
              }));
              return;
            }
      
            setFormData((prev) => ({ ...prev, [name]: file }));
      
            if (file.type.startsWith("image/")) {
              const fileURL = URL.createObjectURL(file);
              setImagePreview(fileURL);
            }
          }
        } else {
          setFormData((prev) => ({ ...prev, [name]: value }));
        }
      };
      
      useEffect(() => {
        return () => {
          if (imagePreview) {
            URL.revokeObjectURL(imagePreview);
          }
        };
      }, [imagePreview]);
    
      const validateForm = () => {
        const newErrors = {};
     
    
        if (!formData.name) newErrors.name = "NAME (According to School Register) is required.";
        if (!formData.motherName) newErrors.motherName = "NAME (Present) is required.";
        if (!formData.dob) newErrors.dob = "DATE OF BIRTH is required."; 
        if (!formData.phone2) newErrors.phone2 = "CONTACT NUMBER is required.";
        if (!formData.phone) newErrors.phone = "WHATSAPP NUMBER is required.";
        if (!formData.email) newErrors.email = "Email ID is required.";
        if (!formData.address) newErrors.address = "CURRENT CITY is required.";
        if (!formData.address1) newErrors.address1 = "STATE is required";
        if (!formData.location) newErrors.location = "COUNTRY is required.";
        if (!formData.fromYear) newErrors.fromYear = "ADMISSION YEAR (only year) is required.";
        if (!formData.toYear) newErrors.toYear = "PASS OUT YEAR (only year) is required.";
        if (!formData.howKnow) newErrors.howKnow = "DO YOU REMEMBER YOUR PRINCIPAL?  is required.";
        if (!formData.qualification) newErrors.qualification = "HIGHEST EDUCATIONAL QUALIFICATION is required.";
    
    
        if (formData.attachments1) {
          const validTypes1 = ['image/jpeg', 'image/jpg', 'application/pdf'];
          if (!validTypes1.includes(formData.attachments1.type)) {
            newErrors.attachments1 = "Invalid file type. Only PDF are allowed.";
          }
        }
    
        return newErrors;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
      
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return;
        }
      
        const processFile = async (file) => {
          if (!file) return null;
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve({ name: file.name, type: file.type, data: reader.result.split(",")[1] });
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        };
      
        try {
          const fileData = await processFile(formData.attachments1);
          const dataToSend = { ...formData, attachments1: fileData };
      
          console.log("Submitting form data:", dataToSend);
      
          const response = await fetch(
            "https://script.google.com/macros/s/AKfycbz_vABEu48gDirhVRFC1z3XHTj9zl2wTcOF0axoSksTQKD4CcpwVWHnLysZgwEHEWUX/exec",
            {
                mode: "no-cors",
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(dataToSend),
            }
          );
      
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          setSuccessMessage("Your Form Submitted Successfully.");
          setFormData(initialFormState); // Reset form
      
        } catch (error) {
          console.error("Submission error:", error);
          setSuccessMessage("Your Form Submitted Successfully.");
        }
      };
      
    return (
        <>
            <Header />
            <Breadcrumb title="Alumni" tabName="Alumni " />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p><b>Dear Alumni,</b></p>
                            <p>    Warm greetings from <b>Nirmala Convent School (NCS), Rajkot</b>!</p>
                                <p>As a cherished member of the NCS family, we are thrilled to reconnect with you and strengthen our alumni network. We invite you to take a moment to register yourself through the link provided below. This platform will serve as a bridge to reconnect with your fellow Nirmala alumni, share memories, and stay updated on upcoming events and initiatives.</p>
                                
                                {/* <a href='https://forms.gle/YZVCJQBkgdq8DLDA6' target='_blank'> <b>https://forms.gle/YZVCJQBkgdq8DLDA6</b></a><br /> */}
<p>                                Should you have any questions or need assistance, please feel free to reach out via email or the contact number provided in the registration form.</p>
                                <p>We look forward to hearing from you and rebuilding the bonds that make the NCS community so special.</p>
                                <p>Thank you, and we eagerly await your response!</p>
                                <p><i>Warm regards,<br /></i>
                                NCS, Rajkot</p>
                        </div>
                        <div className='col-xl-12'>
                            <div class="col-lg-12 mt-4 app-form">
                                <h3 className="text-center">Alumni Form</h3>
                                <div className="career-form">

                                    <form className='careerform-feilds' onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-4 pos-rel">
                                                <label>Your Photograph  (Kindly upload a clear image):</label>
                                                <div className="mt-2">
                                                    <img
                                                        src={imagePreview}
                                                        alt="Preview"
                                                        className="img-fluid"
                                                        style={{ maxWidth: "200px", borderRadius: "5px" }}
                                                    />
                                                </div>
                                                <input
                                                    className="form-control img-option"
                                                    type="file"
                                                    name="attachments1"
                                                    id="fileInput1"
                                                    onChange={handleChange}
                                                    accept=".jpg, .jpeg, .pdf"                                                
                                                />
                                                {errors.attachments1 && <div className="error">{errors.attachments1}</div>}


                                            </div>
                                            {/* Name */}
                                            <div className="col-lg-8">
                                                <div className="row">

                                                    <div className="col-lg-6">
                                                        <label>Name (According to School Register)*</label>
                                                        <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange} required />
                                                        {errors.name && <div className="error">{errors.name}</div>}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label>Name (Present)*</label>
                                                        <input type="text" name="motherName" className="form-control" value={formData.motherName} onChange={handleChange} required />
                                                        {errors.motherName && <div className="error">{errors.motherName}</div>}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label>Date of Birth*</label>
                                                        <input type="date" name="dob" className="form-control" value={formData.dob} onChange={handleChange}  required />
                                                        {errors.dob && <div className="error">{errors.dob}</div>}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label>Date of Anniversary</label>
                                                        <input type="date" name="experience" className="form-control" onChange={handleChange} value={formData.experience} required />
                                                        {errors.experience && <div className="error">{errors.experience}</div>}
                                                    </div>

                                                    
                                                </div>
                                                </div>
                                                
                                            <div className="col-lg-12">
                                                <div className="row">
                                                <div className="col-lg-4">
                                                        <label>General Register Number</label>
                                                        <input  type="text" name="phone1" className="form-control" onChange={handleChange} value={formData.phone1} />
                                                        {errors.address && <div className="error">{errors.address}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Contact Number*</label>
                                                        <input type="tel" pattern="\d{10}" inputMode="numeric" name="phone2" onChange={handleChange} className="form-control" maxLength={10} value={formData.phone2} required />
                                                        {errors.phone2 && <div className="error">{errors.phone2}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Whatsapp Number*</label>
                                                        <input type="tel" pattern="\d{10}" inputMode="numeric" name="phone"  onChange={handleChange} className="form-control" maxLength={10} value={formData.phone} required />
                                                        {errors.phone && <div className="error">{errors.phone}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Email Id*</label>
                                                        <input type="email" name="email" className="form-control"  onChange={handleChange} value={formData.email} required />
                                                        {errors.email && <div className="error">{errors.email}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Current City*</label>
                                                        <input type="text" name="address" className="form-control" onChange={handleChange} value={formData.address} required />
                                                        {errors.address && <div className="error">{errors.address}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>State*</label>
                                                        <input type="text" name="address1" className="form-control" onChange={handleChange} value={formData.address1} required />
                                                        {errors.address1 && <div className="error">{errors.address1}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Country*</label>
                                                        <input type="text"  name="location" className="form-control" onChange={handleChange} value={formData.location} required />
                                                        {errors.location && <div className="error">{errors.location}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Admission Year (only year)*</label>
                                                        <input type="text" name="fromYear" className="form-control"  onChange={handleChange} value={formData.fromYear} required />
                                                        {errors.fromYear && <div className="error">{errors.fromYear}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Pass Out Year (only year)*</label>
                                                        <input type="text" name="toYear" className="form-control" onChange={handleChange}  value={formData.toYear} required />
                                                        {errors.toYear && <div className="error">{errors.toYear}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Do You Remember Your Principal?*</label> 
                                                        <select value={formData.howKnow} name="howKnow" className="form-control" onChange={handleChange} >
                                                           
                                                            <option>Yes</option>
                                                            <option>No</option>
                                                        </select>
                                                        {errors.howKnow && <div className="error">{errors.howKnow}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>If Yes, Name Please</label>
                                                        <input type="text"   name="howKnow1" onChange={handleChange} className="form-control" value={formData.howKnow1} required />
                                                        {errors.howKnow1 && <div className="error">{errors.howKnow1}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>HIGHEST EDUCATIONAL QUALIFICATION*</label>
                                                        <input type="text" name="qualification" className="form-control" onChange={handleChange} value={formData.qualification} required />
                                                        {errors.qualification && <div className="error">{errors.qualification}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>LATEST PROFESSIONAL PROFILE</label>
                                                        <input type="text" name="profession" className="form-control" onChange={handleChange} value={formData.profession} required />
                                                        {errors.profession && <div className="error">{errors.profession}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>NAME AND ADDRESS OF WORK PLACE</label>
                                                        <input type="text" name="school1" className="form-control" onChange={handleChange} value={formData.school1} required />
                                                        {errors.school1 && <div className="error">{errors.school1}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>PROUD ACHIEVEMENTS</label>
                                                        <input type="text" name="message" className="form-control" onChange={handleChange} value={formData.message} required />
                                                        {errors.message && <div className="error">{errors.message}</div>}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>FOND MEMORIES OF STUDENT LIFE AT NCS</label>
                                                        <input type="text" name="messageTitle" className="form-control" onChange={handleChange} value={formData.messageTitle} required />
                                                        {errors.messageTitle && <div className="error">{errors.messageTitle}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="submit">
                                            <input type="submit" value="Submit" />
                                        </div>
                                    </form>
 
                                    {successMessage && (
  <div className="success-message">
    <p>{successMessage}</p>
  </div>
)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Alumni